<template>
  <div class="home">
    <div class="logo-horizontal">
      <router-link to="/"
        ><img src="@/assets/horizontal.png" alt=""
      /></router-link>
    </div>
    <div v-if="!token && mountedvar">
      <h1>Az oldal használatához be kell jelentkezni!</h1>
    </div>
    <div v-if="token">
      <table>
        <tr v-if="myCoins">
          <td class="p-3 coinimg"></td>
          <td class="p-3 coin"></td>
          <td class="p-3 coinname">Név</td>
          <td class="p-3 coinlastprice">Ár/coin</td>
          <td class="p-3 coinprevday">24h</td>
          <td class="p-3 coinqty">Coin(db)</td>
          <td class="p-3 coinqty">Érték</td>
        </tr>
        <tr v-for="mycoin in myCoins" :key="mycoin.name" class="assets">
          <td class="p-3 coinimg">
            <img
              :src="'https://www.elektrogeek.hu/CryptoLogos/' + mycoin.symbol + '.png'"
              class="coinlogos"
            />
          </td>
          <td class="p-3 coin">
            <span>{{ mycoin.symbol }}</span>
          </td>
          <td class="p-3 coinname">
            <span>{{ mycoin.name }}</span>
          </td>
          <td class="p-3 coinlastprice">
            <span>${{ mycoin.lastprice }}</span>
          </td>
          <td
            class="p-3 coinprevday"
            :class="{
              red: mycoin.prevday < 0,
              black: mycoin.prevday == 0,
              green: mycoin.prevday > 0,
            }"
          >
            <span>{{ parseFloat(mycoin.prevday).toFixed(2) }}%</span>
          </td>
          <td class="p-3 coinqty">
            <span>{{ mycoin.qty }}</span>
          </td>
          <td class="p-3 coinvalue">
            <span>${{ (parseFloat(mycoin.qty) * parseFloat(mycoin.lastprice)).toFixed(2) }}</span>
          </td>
        </tr>
      </table>
      <div v-if="myCoins">
        <div class="foot">Összesen: ${{ allValue }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Api from "./../util/api";

export default {
  name: "home",
  data() {
    return {
      myCoinsBeforeFiltered: null,
      myCoins: null,
      mountedvar: false
    };
  },
  watch: {
    token() {
      if(this.token) {
        this.loadData();
      }
    }
  },
  created() {
    this.loadData();
  },
  mounted() {
    setTimeout(function(){
      this.mountedvar = true;
    },500);
  },
  methods: {
    loadData() {
      this.$loading(true);
        let self = this;

        Api.pricesWriteToDb(self.token)
            .then(function (response) {
              self.myCoinsBeforeFiltered = response.data;
              self.myCoins = self.myCoinsBeforeFiltered.sort((a,b) => (parseInt(a.cmc_rank) > parseInt(b.cmc_rank)) ? 1 : ((parseInt(b.cmc_rank) > parseInt(a.cmc_rank)) ? -1 : 0));
              //self.myCoins = self.myCoinsBeforeFiltered.filter(myCoin => myCoin.qty != 0);
              self.$loading(false);
            })
            .catch((err) => console.log(err));
    },
    trade(side,mycoin) {
      this.$loading(true);
      let lastprice = mycoin.lastprice;
      let symbol = mycoin.name;
      let unit = mycoin.unit;
      let price = 0.00000000;
      let type = "MARKET";
      let unitprice = this.unitPrice;
      let quoteOrderQty = 0;
      
      let quantity = parseFloat(unitprice) / parseFloat(lastprice);
      quantity = quantity.toFixed(mycoin.stepsize);

      let self = this;
      Api.trade(self.token,side,symbol,quantity,quoteOrderQty,price,type,unit)
        .then(function (response) {

          if(response.data["status"] == "FILLED") {
            self.$toast.success("Sikeres tranzakció");
          } else {
            self.$toast.error("Sikertelen tranzakció");
            self.$toast.error(response.data.msg);
          }
          self.refresh();
        })
        .catch((err) => console.log(err));
    }
  },
  computed: {
    ...mapGetters(["token"]),
    allValue() {
      let allValue = 0.0;
      if (this.myCoins) {
        this.myCoins.map(function (coin) {
          allValue = allValue + parseFloat(coin.qty) * parseFloat(coin.lastprice);
        });
      }
      return allValue.toFixed(2);
    },
  },
};
</script>

<style scoped>
.logo-horizontal {
  width: 260px;
  margin: 20px auto;
}
h1 {
  text-align: center;
}
.assets {
  margin: 20px;
  font-size: 110%;
}
.coinlogos {
  width: 40px;
  border-radius: 40px;
}
table {
  margin: 50px auto;
}
.coinimg {
  color: black;
}
.coin {
  font-weight: bold;
}
.coinname {
  color: rgb(100, 100, 100);
  text-align: left;
}
.coinlastprice {
  color: black;
  text-align: right;
}
.coinprevday {
  color: black;
  text-align: right;
}
.coinqty {
  color: black;
  text-align: right;
}
.coinvalue {
  color: black;
  text-align: right;
}
.red {
  color: red;
}
.green {
  color: rgb(27, 163, 56);
}
.black {
  color: black;
}
.foot {
  width: 400px;
  margin: 10px auto;
  font-size: 120%;
  font-weight: bold;
  text-align: center;
}
.foot:last-child {
  margin-bottom: 100px;
}
</style>