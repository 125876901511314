import axios from "axios";

export default class Api {
  static registration(name, email, password) {
    const url = process.env.VUE_APP_API_URL + "/api/registration.php";
    return axios.post(url, {
      name: name,
      email: email,
      password: password
    });
  }

  static login(name, password, stay) {
    const url = process.env.VUE_APP_API_URL + "/api/login.php";
    return axios.post(url, {
      name: name,
      password: password,
      stay: stay
    });
  }

  static logout(token) {
    const url = process.env.VUE_APP_API_URL + "/api/logout.php";
    return axios.post(url, {
      token: token
    });
  }

  static checktoken(token) {
    const url = process.env.VUE_APP_API_URL + "/api/checktoken.php";
    return axios.post(url, {
      token: token
    });
  }

  static checkName(name) {
    const url = process.env.VUE_APP_API_URL + "/api/checkname.php";
    return axios.post(url, {
      name: name
    });
  }

  static checkEmail(email) {
    const url = process.env.VUE_APP_API_URL + "/api/checkemail.php";
    return axios.post(url, {
      email: email
    });
  }

  static validation(id) {
    const url = process.env.VUE_APP_API_URL + "/api/validation.php";
    return axios.post(url, {
      id: id
    });
  }

  static forgot(email) {
    const url = process.env.VUE_APP_API_URL + "/api/forgot.php";
    return axios.post(url, {
      email: email
    });
  }

  static newpass(oldpassword, password, param, token) {
    const url = process.env.VUE_APP_API_URL + "/api/newpass.php";
    return axios.post(url, {
      oldpassword: oldpassword,
      password: password,
      param: param,
      token: token
    });
  }

//Kripto árak
  static price() {
    const url = "https://www.binance.com/api/v3/ticker/24hr";
    return axios.get(url);
  }

  static prices() {
    const url = process.env.VUE_APP_API_URL + "/api/binance/prices.php";
    return axios.get(url);
  }

  static pricesWriteToDb(token) {
    const url = process.env.VUE_APP_API_URL + "/api/binance/priceswritetodb.php";
    return axios.post(url,{token: token});
  }

  static assets() {
    const url = process.env.VUE_APP_API_URL + "/api/binance/single.php";
    return axios.get(url);
  }

  static trade(token,side,symbol,quantity,quoteOrderQty,price,type,unit) {
    const url = process.env.VUE_APP_API_URL + "/api/binance/trade.php";
    return axios.post(url,{
      token: token,
      side: side,
      symbol: symbol,
      quantity: quantity,
      quoteOrderQty: quoteOrderQty,
      price: price,
      type: type,
      unit: unit
    });
  }

  static coinmarketcap() {
    const url = process.env.VUE_APP_API_URL + "/api/binance/coinmarketcap.php";
    return axios.get(url);
  }
}
