<template>
  <div class="cont">
    <b-navbar toggleable="lg" type="dark" variant="info">
      <b-navbar-brand href="/">ELEKTROGEEK</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item href="/"
            ><b-icon icon="pencil-square"></b-icon
            ><span class="ml-1">Blog</span></b-nav-item
          >

          <b-nav-item href="/"
            ><b-icon icon="cart4"></b-icon
            ><span class="ml-1">Piac</span></b-nav-item
          >

          <b-nav-item href="/"
            ><b-icon icon="receipt"></b-icon
            ><span class="ml-1">Nyilvántartó</span></b-nav-item
          >
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <!--<b-nav-form>
            <b-form-input
              size="sm"
              class="mr-sm-2 input-search"
              placeholder="Keresés..."
            ></b-form-input>
            <b-button size="sm" class="my-2 my-sm-0" type="submit"
              ><b-icon icon="search"></b-icon></b-button
            >
          </b-nav-form>-->
          <b-nav-item v-if="token && !firstDelay" href="/"
            ><b-icon icon="person"></b-icon
            ><span class="ml-1">Profilom</span></b-nav-item
          >

          <b-nav-item v-if="!token && !firstDelay" href="/login"
            ><b-icon icon="box-arrow-in-right"></b-icon
            ><span class="ml-1">Bejelentkezés</span></b-nav-item
          >

          <b-nav-item v-if="token && !firstDelay" @click="logOut()"
            ><b-icon icon="box-arrow-left"></b-icon
            ><span class="ml-1">Kijelentkezés</span></b-nav-item
          >
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Api from "../util/api";

export default {
  name: "topmenu",
  data() {
    return {
      firstDelay: false
    };
  },
  methods: {
    logOut() {
      Api.logout(localStorage.getItem("token"))
        .then(function () {})
        .catch((err) => console.log(err));
      this.$store.commit("setToken", "");
    },
  },
  created() {
    let self = this;
    setTimeout(function() {
      self.firstDelay = false;
    }, 1000);
  },  
  computed: {
    ...mapGetters(["token"]),
  },
};
</script>

<style scoped>
.cont {
  position: sticky;
  top: 0;
  z-index: 999;
}
.input-search {
  width: 75%;
}
</style>
