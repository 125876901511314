<template>
  <div id="app">
    <topmenu />
    <router-view />
  </div>
</template>

<script>
import topmenu from "@/components/topmenu.vue";
import Api from "./util/api";

export default {
  name: "app",
  components: {
    topmenu,
  },
  created() {
    if (localStorage.getItem("token")) {
      let self = this;
      Api.checktoken(localStorage.getItem("token"))
        .then(function (response) {
          if (response.data) {
            //console.log(response.data);
            let tokenIn = localStorage.getItem("token");
            self.$store.commit("setToken", tokenIn);
          } else {
            self.$store.commit("setToken", "");
          }
        })
        .catch((err) => console.log(err));
    }
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}
</style>
