import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: "",
    showLoginModal: false,
    btcp: null
  },
  mutations: {
    initialiseStore(state) {
      if (localStorage.getItem("token")) {
        state.token = localStorage.getItem("token");
      }
    },

    setToken: (state, dataToken) => {
      (state.token = dataToken), localStorage.setItem("token", dataToken);
    },

    setShowLoginModal: (state, showLoginModal) => (state.showLoginModal = showLoginModal),
  },
  getters: {
    showLoginModal: state => { return state.showLoginModal },
    token: state => { return state.token }
  }
});
